
export default {
    menu: [{
        name: "about",
        link: "/about"
    },{
        name: "work",
        link: "/selected-projects"
    },{
      name: "contact",
      link: "/contact"
    }]
}