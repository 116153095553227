import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
import Header from './components/Header/Header';
import Home from './views/Home/Home';
import About from './views/About/About';
import Contacts from './views/Contacts/Contacts';
import Portfolio from './views/Portfolio/Portfolio';
import Project from './views/Project/Project';
import apiData from './api';
import './App.css';

const RouteContainer = posed.div({
  enter: { opacity:1, beforeChildren: true, staggerChildren: 50 },
  exit: {opacity:0 }
});
function App({history}) {

  //history.listen((location, action) => {
      // location is an object like window.location
  //});

  return (
    <Route
      render={({ location }) => (
      <div className="App">
          <Header menu={apiData.menu}/>
          <main id="main">
          <PoseGroup>
            <RouteContainer key={location.pathname}>
              <Switch location={location}>
                <Route exact path="/" component={Home} key="home" />
                <Route path="/about" component={About} key="about" />
                <Route path="/selected-projects" key="portfolio"
                       render={(props) => <Portfolio {...props} />} />
                <Route path="/project/:projectId" key="project"
                       render={(props) => <Project {...props} />} />
                <Route path="/contact" component={Contacts} key="contacts" />
              </Switch>
            </RouteContainer>
          </PoseGroup>
          </main>
      </div>
    )}
    />
  );
}

export default withRouter(App);
