import React from 'react';
import posed from 'react-pose';
import {ReactComponent as Hamburger} from './Hamburger.svg';
import Nav from './Nav'

const tween = {
  type: 'spring',
  stiffness: 100
};

const SidePanel = posed.div({
  open: {
    width: '70vw',
    height: '100vh',
    transition: tween,
    transform: 'translateX(0)',
    flip: true
  },
  closed: {
    
    width: 100,
    height: 100,
    transition: tween,
    transform: 'translateX(70vw)',
    flip: true
  }
});

export default function Panel(props) {
  let {
    menu
  } = props;
  
  return (
    <div className={'side-menu'}>
      <div>
        <Hamburger/>
      </div>
      <SidePanel>
        <Nav menu={menu}/>
      </SidePanel>
    </div>
  )
}