import React, {Component} from 'react';
import ImgPlaceholder from 'components/utils/ImgPlaceholder'
import LazyLoad from 'react-lazyload';
import './LazyImage.css';

class LazyImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.image = React.createRef();
    this.handleLoad = this.handleLoad.bind(this);
  }
  
  componentDidMount() {
    const img = this.image.current;
    if (img && img.complete) {
      this.handleLoad();
    }
  }
  
  handleLoad() {
    this.state && this.setState({loaded: true});
  }
  
  loadingClass() {
    return !this.state.loaded ? ' lazy-wrap lazy--loading' : ' lazy-wrap'
  }
  
  render() {
    const {
      height,
      width,
      ratio,
      placeholderSrc,
      src,
      offset,
      children
    } = this.props;
    
    return (
      <React.Fragment>
        {
          <ImgPlaceholder
            src={placeholderSrc}
            height={height}
            width={width}
            ratio={ratio}
            className={this.state.loaded ? 'lazy-placeholder--hidden' : 'lazy-placeholder'}
          />
        }
        <LazyLoad
          once
          height={height}
          offset={offset || 3000}
          throttle={100}
          className={this.loadingClass()}
          preventLoading={this.state.loaded}
          placeholder={<i></i>}
        >
          
          {
            typeof children === 'function' ?
              children({
                onLoad: this.handleLoad,
                ref: this.image,
                className: this.loadingClass()
              }) : (
                <img className={"image-responsive" + this.loadingClass()}
                     ref={this.image}
                     src={src}
                     onLoad={this.handleLoad}
                     alt={this.props.alt || 'Viola Dressino'}
                />)
          }
        
        </LazyLoad>
      </React.Fragment>
  );
  }
  }
  
  export default LazyImage;