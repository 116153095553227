import React from 'react';
import {Link} from 'react-router-dom';
import './PrevNextNav.css';

export default function PrevNextNav(props) {
  let {
    prev,
    next,
    current,
    hide,
  } = props;
  
  const hiddenClass = hide ? 'prevnext-hidden' : '';
  
  return (
    <div className={`prevnext-nav ${hiddenClass}`}>
      <div className="prevnext-nav__prev">
        {prev && <Link to={prev.link} title={'Previous project'}>{'<'}</Link>}
      </div>
      <div className="prevnext-nav__label">
        <span>{current.name}</span>
      </div>
      <div className="prevnext-nav__next">
        {next && <Link to={next.link} title={'Next project'}>{'>'}</Link>}
      </div>
    </div>
  )
}