import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {AnimatedItem} from 'views/Animations';
import ScrollToTopOnMount from 'components/utils/ScrollToTopOnMount'
import apiData from 'api/';
import './Home.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.description = apiData.about;
  }
  
  render() {
    return (
      <div className="container">
        <ScrollToTopOnMount/>
        <AnimatedItem>
          <div className="hero hero--block">
            
            <div className="par--block">
              Hi! I'm Viola, an Italian <Link to={'/about'}>architect</Link> living in <span
              className="st">sunny</span> Amsterdam.
            </div>
            <div className="par--block">
              Check out my <Link to={'/selected-projects'}>latest work</Link>, <Link
              to={'/about'}>resume</Link> or <Link
              to={'/contact'}>get in touch</Link>.
            </div>
          </div>
        </AnimatedItem>
      </div>
    );
  }
}

export default Home;