import React, {Component} from 'react';
import {AnimatedItem} from 'views/Animations';
import './Contacts.css';

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.email = 'violadressino@gmail.com';
    this.linkedId = 'https://www.linkedin.com/in/viola-dressino/';
    this.instagram = 'https://www.instagram.com/violadress/';
  }
  
  render() {
    return (
      <div className="container">
        <AnimatedItem>
          <div className="hero hero--block">
            <div className="par">
              Get in touch via <a href={`mailto:${this.email}`} className={'email'}>email</a>,
            </div>
            <div className="par">
              or hit me up on <a href={this.linkedId} className={'linkedin'} target="_blank" rel="noopener noreferrer">linkedin</a> or <a
              href={this.instagram} className={'instagram'} target="_blank" rel="noopener noreferrer">instagram</a>.
            </div>
          </div>
        </AnimatedItem>
      </div>
    );
  }
}

export default Contacts;