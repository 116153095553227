import menu from './menu';
import {about} from './about';
import {projects} from './projects';
//import projectsImages from './projectsImages';

const data = {
  about,
  projects,
  ...menu
};

export default data;