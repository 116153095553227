import { Component } from 'react';
import PropTypes from 'prop-types'

class ScrollWrapper extends Component {
    constructor(props) {
      super(props);
      this.handleScroll = this.handleScroll.bind(this);
    }
    
    handleScroll(event) {
        // Call the passed-in prop
        if (this.props.onWindowScroll) {
            this.props.onWindowScroll(event);
        }
    }

    render () {
        return this.props.children || null;
    }

    componentDidMount() {
        if (this.props.onWindowScroll) {
            window.addEventListener("scroll", this.handleScroll);
        }
    }

    componentWillUnmount() {
        if (this.props.onWindowScroll) {
            window.removeEventListener("scroll", this.handleScroll);
        }
    }
}

ScrollWrapper.propTypes = {
    onWindowScroll: PropTypes.func
};

export default ScrollWrapper;

/*
// USAGE
var ComponentA = React.createClass({
    handleScroll: function(event) {
        console.log("ComponentA's handleScroll");
    },

    render: function() {
        return (
            <ScrollWrapper onWindowScroll={this.handleScroll}>
                <div>whatever</div>
            </ScrollWrapper>
        );
    }
});
*/