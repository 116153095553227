import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

class Nav extends Component {
  
  componentDidMount() {
    const {history} = this.props;
    
    this.setState({link: history.location.pathname});
    
    history.listen((location, action) => {
      this.setState({link: location.pathname});
    });
  }
  
  isSelected(item) {
    return (this.state && this.state.link === item) ?
      'active' : ''
  }
  
  render() {
    return (
      <ul>
        {this.props.menu.map((item) =>
          <li key={item.link} className={this.isSelected(item.link)}>
            <Link to={item.link}>{item.name}</Link>
          </li>
        )}
      </ul>
    );
  }
}

export default withRouter(Nav);