import React from 'react';
import Panel from './Panel';
import Nav from './Nav'
import './Menu.css';

export default function Menu(props) {
  let {
    menu
  } = props;
  
  return (
    <nav>
      <Nav menu={menu}/>
      <Panel menu={menu}/>
    </nav>
  )
}