import posed  from 'react-pose';

export const AnimatedTitle = posed.h2({
    enter: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 }
});

export const AnimatedItem = posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 }
});

export const AnimatedCaption = posed.div({
    enter: { opacity:1, x: 0 },
    exit: { opacity:0, x: 20 }
});

export const AnimatedHoverImg = posed.div({
    hoverable: true,
    init: { filter: 'grayscale(80%)' },
    hover: { filter: 'grayscale(0%)' }
  });