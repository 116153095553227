import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {ReactComponent as VdArchitetture} from 'svg/vdArchitetture.svg';
import Menu from 'components/Menu/Menu';
import './Header.css';

class Header extends Component {
  
  componentDidMount() {
    const {history} = this.props;
    
    this.setState({link: history.location.pathname});
    history.listen((location, action) => {
      this.setState({link: location.pathname});
    });
  }
  
  isSelected(item) {
    return (this.state && this.state.link === item) ?
      'active' : ''
  }
  
  render() {
    return (
      <header>
        <div className="container">
          <h1>
            <Link to={'/'}>
              <VdArchitetture aria-label="Viola Dressino Architect"/>
            </Link>
          </h1>
          <Menu menu={this.props.menu}/>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);