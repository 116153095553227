import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Parallax from 'components/utils/Parallax';
import LazyImage from 'components/utils/LazyImage/LazyImage';
import './PortfolioItem.css';

const PUBLIC_URL = process.env.PUBLIC_URL;

class PortfolioItem extends Component {
  constructor(props) {
    super(props);
    this.id = `project_${this.props.link}`;
    this.link = `/project/${this.props.link}`;
    this.cover = this.props.images[0];
    this.cover.link = PUBLIC_URL + this.cover.url;
  }
  
  render() {
    return (
      <Link
        className="portfolio-item clearfix"
        key={this.id} id={this.id}
        to={this.link}
      >
        <div className="portfolio-item__image">
          <LazyImage
            height={this.cover.height}
            width={this.cover.width}
            ratio={this.cover.ratio}
            placeholderSrc={this.cover.lqip}
            src={this.cover.link}
          />
        </div>
        <Parallax speed={2} wrapper={`#${this.id}`} data-rellax-max={8}>
        <div className="portfolio-item__title">
            {this.props.name}
        </div>
        </Parallax>
      </Link>
    );
  }
}

export default PortfolioItem;