import React from 'react';

export default function ProjectInfoBlock(props) {
  const {
    title,
    content
  } = props;
  
  if(!(title && content)) {
    return null;
  }
  
  return (
      <div className="project-item__info">
        <span>{title}</span>
        <div>{content}</div>
      </div>
  )
}