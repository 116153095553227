import React, {Component} from 'react';

export default class ImgPlaceholder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    
    this.styles = {
      background: `url("${props.src}")`,
      height: 'auto',
      //width: "100%",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      filter: 'blur(3px)'
    };
  }
  
  componentDidMount() {
    const relativeWidth = this.container.offsetWidth;
    const relativeHeight = relativeWidth/this.props.ratio;
    
    this.setState({
      width: relativeWidth,
      height: relativeHeight
    })
  }
  
  render() {
    return (
      <div ref={el => (this.container = el)} style={this.styles} className={this.props.className}>
        {this.state && <svg width={this.state.width} height={this.state.height}/>}
      </div>
    )
  }
}