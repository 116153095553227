export function getByKey(array, key, id) {
    return array.find(item => item[key] === id);
}

export function getProject(projects, id) {
    return getByKey(projects, 'link', id);
}

export function getPrevProject(projects, id) {
    const currentIndex = projects.findIndex(item => item['link'] === id);
    return currentIndex > 0 ? projects[currentIndex-1] : false
}
export function getNextProject(projects, id) {
    const currentIndex = projects.findIndex(item => item['link'] === id);
    return currentIndex < projects.length-1 ? projects[currentIndex+1] : false
}