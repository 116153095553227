import React from 'react';
import posed from 'react-pose';
import './ZoomImg.css';

const Frame = posed.div({
  init: {
    applyAtEnd: { display: 'none' },
    opacity: 0
  },
  zoom: {
    applyAtStart: { display: 'block' },
    opacity: 1
  }
});

const transition = {
  duration: 400,
  ease: [0.08, 0.69, 0.2, 0.99]
};

const Image = posed.img({
  init: {
    position: 'static',
    width: 'auto',
    height: 'auto',
    transition,
    flip: true,
    zIndex: 101
  },
  zoom: {
    position: 'fixed',
    top: 0,
    left: "10%",
    right: "auto",
    bottom: 0,
    width: "80%",
    transition,
    flip: true
  }
});

class ZoomImg extends React.Component {
  state = { isZoomed: false };

  constructor(props){
    super(props);
    this.imgRef = React.createRef();
    this.timer = null;
  }

  zoomIn() {
    const rect = this.imgRef.current.getBoundingClientRect();
    const imageWidth = rect.width;
    const imageHeight = rect.height;
    window.addEventListener('scroll', this.zoomOut);
    this.setState({ isZoomed: true, imageWidth, imageHeight });
    
    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  zoomOut = () => {
    window.removeEventListener('scroll', this.zoomOut);
    this.setState({ isZoomed: false });
    
    if(!this.timer) {
      this.timer = setTimeout(_ => {
        const imageWidth = 'auto';
        const imageHeight = 'auto';
        this.setState({imageWidth, imageHeight});
        clearTimeout(this.timer);
        this.timer = null;
      }, 300);
    }
  };

  toggleZoom = () => (this.state.isZoomed ? this.zoomOut() : this.zoomIn());

  render() {
    const { isZoomed, imageWidth, imageHeight } = this.state;
    const { ...props } = this.props;
    const pose = isZoomed ? 'zoom' : 'init';

    return (
        <div
          className="zoom-img"
          style={{ width: imageWidth, height: imageHeight }}
          onClick={this.toggleZoom}
          ref={this.imgRef}
        >
          <Frame pose={pose} className="frame" />
            <Image pose={pose} {...props} />
        </div>
    );
  }
}

export default ZoomImg;
