import React, {Component} from 'react';
import {AnimatedItem, AnimatedTitle, AnimatedCaption} from 'views/Animations';
import ScrollToTopOnMount from 'components/utils/ScrollToTopOnMount'
import apiData from 'api/';
import './About.css';

class About extends Component {
  constructor(props) {
    super(props);
    this.description = apiData.about;
    this.resumeUrl = 'https://drive.google.com/file/d/1ReLfenKusA-gi13NXdFY8hAM4y-tsCnF/view';
    this.portfolioUrl = 'https://drive.google.com/file/d/19-rzoG8Ww6hgqA3vHUGuKGmBkSeEQVb7/view';
  }
  
  render() {
    return (
      <div className="container">
        <ScrollToTopOnMount/>
        <AnimatedTitle>Hi there!</AnimatedTitle>
        <AnimatedItem>
          <div className="grid">
            <div className="grid-col p" dangerouslySetInnerHTML={{__html:
                `<div class="about__avatar about__avatar--inline">
                    <img class="image-responsive" src="/images/viola-dressino-architect.jpg" alt="Viola Dressino Architect"/>
                  </div> ${this.description} `
            }}/>
            <div className="grid-col">
              <div className="about__avatar-col">
              <AnimatedCaption className="clearfix">
                <div className="about__avatar-wrap clearfix">
                  <div className="about__avatar">
                    <img className="image-responsive" src="/images/viola-dressino-architect.jpg" alt="Viola Dressino Architect"/>
                  </div>
                </div>
              </AnimatedCaption>
              <AnimatedItem className="hero">
                <a href={this.resumeUrl} target="_blank" rel="noopener noreferrer">resume</a>
                <br/>
                <a href={this.portfolioUrl} target="_blank" rel="noopener noreferrer">portfolio</a>
              </AnimatedItem>
              </div>
            </div>
          </div>
        </AnimatedItem>
      </div>
    );
  }
}

export default About;