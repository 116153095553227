import React, {Component} from 'react';
import LazyImage from 'components/utils/LazyImage/LazyImage';
import ScrollToTopOnMount from 'components/utils/ScrollToTopOnMount'
import Parallax from 'components/utils/Parallax'
import ZoomImg from 'components/utils/ZoomImg/ZoomImg'
import {getProject, getPrevProject, getNextProject} from 'views/Project/ProjectService';
import {AnimatedItem, AnimatedTitle} from 'views/Animations';
import apiData from 'api/';
import './Project.css';
import './ProjectInfo.css';
import ProjectInfoBlock from "./ProjectInfoBlock";
import PrevNextNav from "components/PrevNextNav/PrevNextNav";
import ArrowDown from "components/ArrowDown/ArrowDown";
import OnScroll from "../../components/utils/OnScroll";

const PUBLIC_URL = process.env.PUBLIC_URL;

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {project: {}, next: 0, prev: 0};
  }
  
  componentDidMount() {
    const {match: {params}} = this.props;
    const projectId = params.projectId;
    const project = getProject(apiData.projects, projectId);
    const prev = getPrevProject(apiData.projects, projectId);
    const next = getNextProject(apiData.projects, projectId);
    const textBlocks = Project.splitDescription(project.description, project.images.length - 1);
    const blocks = Project.combineBlocks(textBlocks, project.images);
    this.setState({
      id: `project_${project.link}`,
      project,
      prev,
      next,
      blocks
    })
  }
  
  static splitDescription(description, maxChunks) {
    
    let d = description
      .split(/\./g)
      .map(s => s + ".")
      .filter(s => s.length > 3);
    
    if (d.length > maxChunks) {
      
      const dd = [];
      let diff = d.length - maxChunks;
      let diffRatio = Math.round(d.length / maxChunks);
      let i = 0;
      
      for (; i < d.length - 1 && diff >= 0; i += diffRatio) {
        dd.push(d.slice(i, i + diffRatio).join(" "));
        diff--;
      }
      
      if (diff) {
        dd[dd.length - 1] = dd[dd.length - 1] + d.slice(i).join(" ");
      }
      d = dd;
    }
    
    return d;
  }
  
  static combineBlocks(textBlocks, images) {
    if (!textBlocks.length) {
      return images;
    }
    
    const textBlocksRatio = Math.round(textBlocks.length / images.length);
    const blocks = [];
    let j = 0;
    
    for (let i in images) {
      blocks.push(images[i]);
      if (i % textBlocksRatio === 0 && textBlocks[j]) {
        blocks.push(textBlocks[j]);
        j++;
      }
    }
    
    return blocks;
  }
  
  static getImageLink(link) {
    return PUBLIC_URL + link;
  }
  
  projectInfo({project, className}) {
    return (
      <div className={"project-item__info-wrap " + className}>
        <ProjectInfoBlock title={'Studio'} content={project.client}/>
        <ProjectInfoBlock title={'Type'} content={project.type}/>
        <ProjectInfoBlock title={'Year'} content={project.year}/>
        <ProjectInfoBlock title={'Tools'} content={project.software}/>
      </div>
    )
  }
  
  render() {
    
    const {state} = this;
    const {project, prev, next} = state;
    
    return (
      <div className="container" id={state.id}>
        <ScrollToTopOnMount/>
        
        <AnimatedTitle className="project-item__title">
          <Parallax speed={2} wrapper={`#${state.id}`} data-rellax-max={12}>
            {project.name}
          </Parallax>
        </AnimatedTitle>
        
        <AnimatedItem>
          {this.projectInfo({project, className: "top"})}
        </AnimatedItem>
        
        <AnimatedItem>
          {
            Array.isArray(state.blocks) &&
            state.blocks.map((item) => {
              return item && item.url ? (
                <div className="project-item__block project-item__block--image" key={item.url}>
                  <LazyImage
                    height={item.height}
                    width={item.width}
                    ratio={item.ratio}
                    placeholderSrc={item.lqip}
                    src={Project.getImageLink(item.url)}
                  >
                    {p => (
                      <ZoomImg
                        {...p}
                        className={"image-responsive " + p.className}
                        src={Project.getImageLink(item.url)}
                      />
                    )}
                  </LazyImage>
                </div>
              ) : (
                <div className="project-item__block project-item__block--text" key={item}>
                  <p>{item}</p>
                </div>
              )
            })}
        </AnimatedItem>
        
        <OnScroll offset={300}>
          {
            (isAfterOffset =>
                <PrevNextNav
                  prev={prev}
                  next={next}
                  current={project}
                  hide={isAfterOffset}
                />
            )
          }
        </OnScroll>
        
        <ArrowDown/>
      
      </div>
    );
  }
}

export default Project;