import React, {Component} from 'react';
import ScrollToTopOnMount from 'components/utils/ScrollToTopOnMount'
import PortfolioItem from 'views/Portfolio/PortfolioItem';
import {AnimatedItem, AnimatedTitle} from 'views/Animations';
import apiData from 'api/';
import './Portfolio.css';
import ArrowDown from "components/ArrowDown/ArrowDown";


class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.arrowDownRef = React.createRef();
  }
  
  getProjects() {
    return apiData.projects;
  }
  
  render() {
    return (
      <div className="container">
          <ScrollToTopOnMount/>
          <AnimatedTitle>Selected projects</AnimatedTitle>
          <AnimatedItem>
            <div className="portfolio-list clearfix">
              {this.getProjects().map((item, i) =>
                <PortfolioItem key={item.link} {...item} />
              )}
            </div>
          </AnimatedItem>
        <ArrowDown/>
      </div>
    );
  }
}

export default Portfolio;