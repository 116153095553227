import React, {Component} from 'react';
import PropTypes from 'prop-types'
import ScrollWrapper from "./ScrollWrapper";


class OnScroll extends Component {
  constructor(props) {
    super(props);
    this.isScrolled = null;
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {isAfterOffset: false}
  }
  
  componentDidMount() {
    this.handleScroll();
  }
  
  handleScroll(e) {
    if (
      (this.isScrolled === false || this.isScrolled === null)
      && window.pageYOffset > this.props.offset
    ) {
      this.isScrolled = true;
      this.setState({isAfterOffset: false});
    } else if (
      (this.isScrolled === true || this.isScrolled === null)
      && window.pageYOffset <= this.props.offset
    ) {
      this.isScrolled = false;
      this.setState({isAfterOffset: true});
    }
  }
  
  render() {
    const {isAfterOffset} = this.state;
    return (
      <ScrollWrapper onWindowScroll={this.handleScroll}>
        {
          typeof this.props.children === 'function' ?
            this.props.children(isAfterOffset) :
            (this.props.children || null)
        }
      </ScrollWrapper>
    );
  }
}

OnScroll.propTypes = {
  offset: PropTypes.number
};

OnScroll.defaultProps = {
  offset: 50
};

export default OnScroll;