import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {ReactComponent as ArrowDownSvg} from 'svg/ArrowDown.svg';
import './ArrowDown.css';
import OnScroll from "../utils/OnScroll";


class ArrowDown extends Component {
  constructor(props) {
    super(props);
    this.isScrolled = false;
  }
  
  render() {
    return (
      <OnScroll offset={this.props.offset}>
        {isAfterOffset =>
          <div
            className={`arrow-down ${isAfterOffset ? '' : 'fade-out'}`}
            ref={this.arrowDownRef}>
            <ArrowDownSvg/>
          </div>
        }
      </OnScroll>
    );
  }
}

ArrowDown.propTypes = {
  offset: PropTypes.number
};

ArrowDown.defaultProps = {
  offset: 50
};

export default ArrowDown;