import {Component} from 'react';

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    setTimeout(_ => window.scrollTo(0, 0), 300)
  }
  render() {
    return null;
  }
}
export default ScrollToTopOnMount;